import classes from './ProfileAccountCloseAccount.module.css'
import success from 'Assets/Gifs/success.gif'
import Button from 'Components/Button/Button'

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void
}

const DeleteSuccessfulModalBody = ({
  onClick,
}: DeleteDisclaimerModalBodyProps) => {
  return (
    <div className="w-[928px] py-20">
      <img src={success} alt="Success" className="mx-auto" />

      <h4 className="text-center text-[25px] font-medium">
        Message successfully sent
      </h4>

      <p className="text-center max-w-[410px] mx-auto text-[#737373] mt-3.5 mb-9">
        Your message was successfully sent to our support. We would get back to
        you via email
      </p>

      <div className="w-[108px] mx-auto">
        <Button onClick={onClick}>Close</Button>
      </div>
    </div>
  )
}

export default DeleteSuccessfulModalBody
