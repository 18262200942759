import axios from 'axios'

export type TOnboarding = {
  profile_image: string
  first_name: string
  last_name: string
  gender: string
  country: string
  state: string
  employement_status: string
  education: string
  about_us: string
  onboarding_complete: boolean
}

const axiosInstance = axios.create({
  baseURL: 'https://schoolstaging.iseschool.co/api/ise/v1',
})

axiosInstance.interceptors.request.use(
  (request) => {
    const iseAccessToken = localStorage.getItem('iseTutorAccessToken')
    request.headers.Authorization = `Bearer ${iseAccessToken}`
    return request
  },
  (error) => {
    console.log(error)
  }
)

type TSetPasswordPayload = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

type TSetPasswordResponse = {}

const setPassword = (payload: TSetPasswordPayload) => {
  return axiosInstance.post('/tutors/password/change', payload)
}

const services = { setPassword }

export default services
