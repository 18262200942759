import axios from 'axios'

export type TLoginPayload = {
  email: string
  password: string
}

type TNullable<T> = T | null

export type TTutor = {
  id: number
  created_at: TNullable<string>
  update_at: TNullable<string>
  deleted_at: TNullable<string>
  first_name: TNullable<string>
  last_name: TNullable<string>
  profile_image: TNullable<string>
  gender: TNullable<string>
  is_default_password: boolean
  onboarding_complete: boolean
  phone_number: TNullable<string>
  email: TNullable<string>
  linkedIn_profile: TNullable<string>
  twitter_profile: TNullable<string>
  bio: TNullable<string>
  headline: TNullable<string>
  country: TNullable<string>
  state: TNullable<string>
  dob: TNullable<string>
  specialization: TNullable<string>
  years_of_experience: TNullable<string>
  tech_proficiency: TNullable<string>
  certifications: TNullable<string>
  email_changed_at: TNullable<string>
  status: TNullable<string>
  deactivationReason: TNullable<string>
}

export type TLoginResponse = {
  tutor: TTutor
  accessToken: string
  refreshToken: string
}

const baseURL = 'https://schoolstaging.iseschool.co/api/ise/v1'

const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.request.use(
  (request) => {
    const iseAccessToken = localStorage.getItem('iseAccessToken')
    request.headers.Authorization = `Bearer ${iseAccessToken}`
    return request
  },
  (error) => {
    console.log(error)
  }
)

export const login = (payload: TLoginPayload) => {
  return axiosInstance.post<TLoginResponse>('/auth/login/tutor', payload)
}
