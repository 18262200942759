import { useEffect, useState } from 'react'
import AuthLayout from './Components/AuthLayout/AuthLayout'
import Input from 'Components/Input/Input'
import Error from 'Components/Error/Error'
import { capitalize } from 'HelperFunctions/capitalize'
import Button from 'Components/Button/Button'
import PasswordStrength from './Components/PasswordStrength/PasswordStrength'
import services from './Services'

import classes from './SetPassword.module.css'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from 'Router/Routes'
import { useAuth } from 'Context/AuthUserContext'

const SetPassword = () => {
  // Router
  const [error, setError] = useState()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  //   States
  const [passwordIsvalid, setPasswordIsValid] = useState(false)
  const navigate = useNavigate()
  const { defaultPassword } = useAuth()

  useEffect(() => {
    if (!defaultPassword) {
      localStorage.clear()
      navigate(ERoutes.LOGIN)
    }
  }, [])

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const { data: response } = await services.setPassword({
        oldPassword: defaultPassword,
        newPassword: password,
        confirmPassword: confirmPassword,
      })

      // TODO: set notification for account successfully updated
      console.log(response)

      navigate(ERoutes.ON_BOARDING)
    } catch (error: any) {
      const errorMessage = error.response.data.error.responseMessage
      setError(errorMessage)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.feedback}>
          {/* {error && <Error type="success">{capitalize(error)}</Error>} */}

          {error && <Error type="error">{capitalize(error)}</Error>}
        </div>

        <div className={classes.header}>
          <h4 className="text-[39px] text-center font-medium mb-2">
            Reset password
          </h4>

          <p className="leading-[25px] text-center mb-6">
            Enter a new password you’ll remember. We’ll ask for this password
            whenever you want to log in
          </p>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <Input
            label="Enter new password"
            isRequired
            placeholder="Enter Password"
            errorMessage="Passwords is required"
            type="password"
            name="newPassword"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />

          {password && (
            <PasswordStrength
              password={password}
              setPasswordConditionsValid={setPasswordIsValid}
            />
          )}

          <span>
            <Input
              label="Confirm new password"
              isRequired
              type="password"
              placeholder="Enter Password"
              errorMessage="Passwords do not match"
              condition={password === confirmPassword}
              name="confirmPassword"
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />
          </span>

          <div className={classes.buttonSection}>
            <Button
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={
                !passwordIsvalid ||
                password !== confirmPassword ||
                !password ||
                !confirmPassword
              }
            >
              Update password
            </Button>
          </div>
        </form>
      </section>
    </AuthLayout>
  )
}

export default SetPassword
