import Button from 'Components/Button/Button'
import TextArea from 'Components/TextArea/TextArea'
import classes from './ProfileAccountCloseAccount.module.css'

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void
  onClick2: () => void
}

const Close = () => {
  return (
    <svg
      className="w-5 h-5"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5L18 6.5M6 6.5L18 18.5"
        stroke="#2E2E2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

const DeleteDisclaimerModalBody = ({
  onClick,
  onClick2,
}: DeleteDisclaimerModalBodyProps) => {
  return (
    <div className="md:w-[876px] px-20 py-20">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-[25px] font-medium">Deactivate Account</h2>

        <button onClick={onClick}>
          <Close />
        </button>
      </div>

      <hr />

      <p className="mt-3 mb-5">
        Please provide reasons for requesting to deactivate your account.{' '}
      </p>

      <TextArea isRequired label="Feedback" placeholder="Enter message here" />

      <div className={classes.modalButtonSection}>
        {/* <Button type="secondary" onClick={onClick}>
          Cancel
        </Button> */}

        <Button onClick={onClick2}>Send feedback</Button>
      </div>
    </div>
  )
}

export default DeleteDisclaimerModalBody
