import axios from 'axios'
import endpoints from './endpoints'

type TNullable<T> = T | null

export type TTutor = {
  id?: number
  created_at?: TNullable<string>
  update_at?: TNullable<string>
  deleted_at?: TNullable<string>
  first_name: TNullable<string>
  last_name: TNullable<string>
  profile_image: TNullable<string>
  gender: TNullable<string>
  is_default_password?: boolean
  onboarding_complete?: boolean
  phone_number?: TNullable<string>
  email?: TNullable<string>
  linkedIn_profile: TNullable<string>
  twitter_profile?: TNullable<string>
  bio: TNullable<string>
  headline: TNullable<string>
  country: TNullable<string>
  state: TNullable<string>
  dob?: TNullable<string>
  specialization?: TNullable<string>
  years_of_experience?: TNullable<string>
  tech_proficiency?: TNullable<string>
  certifications?: TNullable<string>
  email_changed_at?: TNullable<string>
  status?: TNullable<string>
  deactivationReason?: TNullable<string>
}

const axiosInstance = axios.create({
  baseURL: 'https://schoolstaging.iseschool.co/api/ise/v1',
})

axiosInstance.interceptors.request.use(
  (request) => {
    const iseTutorAccessToken = localStorage.getItem('iseTutorAccessToken')
    request.headers.Authorization = `Bearer ${iseTutorAccessToken}`
    return request
  },
  (error) => {
    return error
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location.reload()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export const completeProfile = (payload: any) => {
  return axiosInstance.post(endpoints.ONBOARDING, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getProfile = (url: string) => {
  return axiosInstance.get<TTutor>(url).then((res) => res.data)
}

export const updateContactInfo = (payload: any) => {
  return axiosInstance.patch(endpoints.UPDATE_CONTACT_INFO, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

type TChangePasswordPayload = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export const changePassword = (payload: TChangePasswordPayload) => {
  return axiosInstance.post(endpoints.CHANGE_PASSWORD, payload)
}

type TChangeEmailPayload = {
  email: string
  password: string
}

export const changeEmail = (payload: TChangeEmailPayload) => {
  return axiosInstance.post(endpoints.CHANGE_EMAIL, payload)
}
