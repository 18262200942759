import classes from './ScheduleContainer.module.css'
import ScheduleCard from '../../Components/ScheduleCard/ScheduleCard'
import noOvervue from '../../Assets/Images/noOvervue.svg'
import EmptyTabComponent from '../../Components/EmptyTabComponent/EmptyTabComponent'
import { useContext, useEffect, useState } from 'react'
import AcceptedModal from '../../Components/Modals/AcceptedModal/AcceptedModal'
import ViewSessionModal from './ViewSessionModal'
import CreateAMeeting from '../ScheduleCalendar/CreateAMeeting'
import { ScheduleContext } from '../../Context/ScheduleContext'
import { mutate } from 'swr'
import { backend_url } from '../../Utilities/global'
// import DeleteDisclaimerModalBody from '../ProfileAccountCloseAccount/DeleteDisclaimerModalBody'
import DeleteModalBody from '../../Components/DeleteModalBody/DeleteModalBody'
import DeleteSuccessfulModalBody from '../../Components/DeleteSuccessfulModalBody/DeleteSuccessfulModalBody'

type LiveClassSessionsProps = {
  height?: string
  padding?: string
  schedule?: any
}

const ScheduleContainer = ({
  height,
  padding,
  schedule,
}: LiveClassSessionsProps) => {
  // States
  const [modals, setModals] = useState({
    viewSession: false,
    displyReschedule: false,
    cancelSession: false,
    cancelSuccess: false,
  })
  const [activeId, setActiveId] = useState('')

  // COntext
  const { requestState, deleteMeeting } = useContext(ScheduleContext)

  // Utils
  const date = new Date()
  const year = String(date.getFullYear())
  const month = String(date.getMonth() + 1).padStart(2, '0')

  // Effects
  useEffect(() => {
    if (requestState?.data) {
      if (requestState?.id === 'reschedule-meeting') {
        setModals((prevState) => {
          return { ...prevState, displyReschedule: false }
        })
      }

      if (requestState?.id === 'delete-meeting') {
        setModals((prevState) => {
          return { ...prevState, cancelSession: false, cancelSuccess: true }
        })
      } else {
        setModals((prevState) => {
          return { ...prevState, cancelSession: false, cancelSuccess: false }
        })
      }

      mutate(
        `${backend_url}/api/ise/v1/booking-session?year=${year}&month=${month}`
      )
    }
  }, [requestState?.data])

  return (
    <>
      {modals.viewSession && (
        <AcceptedModal
          onClick={() => {
            setModals((prevState) => {
              return { ...prevState, viewSession: false }
            })
          }}
          body={
            <ViewSessionModal
              id={activeId}
              onClick3={() => {
                setModals((prevState) => {
                  return { ...prevState, viewSession: false }
                })
              }}
              onClick1={() => {
                setModals({
                  viewSession: false,
                  displyReschedule: false,
                  cancelSession: true,
                  cancelSuccess: false,
                })
              }}
              onClick2={() => {
                setModals({
                  viewSession: false,
                  displyReschedule: true,
                  cancelSession: false,
                  cancelSuccess: false,
                })
              }}
            />
          }
        />
      )}

      {modals?.displyReschedule && (
        <AcceptedModal
          onClick={() => {
            setModals((prevState) => {
              return { ...prevState, displyReschedule: false }
            })
          }}
          body={
            <CreateAMeeting
              onClick={() => {
                setModals((prevState) => {
                  return { ...prevState, displyReschedule: false }
                })
              }}
              year={year}
              month={month}
              id={activeId}
            />
          }
        />
      )}

      {modals?.cancelSession && (
        <AcceptedModal
          onClick={() => {
            setModals((prevState) => {
              return { ...prevState, displyReschedule: false }
            })
          }}
          body={
            <DeleteModalBody
              header="Cancel live session?"
              caption="Are you sure you want to cancel this session? This action cannot be undone, and the student will be notified of the cancellation."
              buttonOneText="Cancel"
              buttonTwoText="Confirm cancellation"
              onClick1={() => {
                setModals((prevState) => {
                  return { ...prevState, cancelSession: false }
                })
              }}
              onClick2={() => {
                deleteMeeting(activeId)
              }}
              requestState={requestState}
            />
          }
        />
      )}

      {modals?.cancelSuccess && (
        <AcceptedModal
          onClick={() => {
            setModals((prevState) => {
              return { ...prevState, displyReschedule: false }
            })
          }}
          body={
            <DeleteSuccessfulModalBody
              header="Session successfully cancelled"
              caption="This session has been deleted from everyobes schedule"
              onClick={() => {
                setModals((prevState) => {
                  return { ...prevState, cancelSuccess: false }
                })
              }}
            />
          }
        />
      )}

      <div className={classes.container} style={{ height, padding }}>
        <ul>
          {schedule?.length > 0 ? (
            schedule?.map((data: any, index: number) => {
              return (
                <ScheduleCard
                  key={data?.id}
                  index={index}
                  startTime={data?.start_time}
                  endTime={data?.end_time}
                  title={data?.session_subject}
                  link={data?.google_meet_link}
                  onClick1={() =>
                    setModals((prevState) => {
                      return { ...prevState, viewSession: true }
                    })
                  }
                  onClick2={() =>
                    setModals((prevState) => {
                      return { ...prevState, displyReschedule: true }
                    })
                  }
                  onClick3={() =>
                    setModals((prevState) => {
                      return { ...prevState, cancelSession: true }
                    })
                  }
                  setActiveId={setActiveId}
                  id={data?.id}
                />
              )
            })
          ) : (
            <EmptyTabComponent
              image={noOvervue as string}
              firstParagraph="You have no schedule at the moment."
              secondParagraph=""
              route="/schedule"
              buttontext="Schedule Appointment"
              showButton={false}
            />
          )}
        </ul>
      </div>
    </>
  )
}

export default ScheduleContainer
