import { useState, ChangeEvent, Dispatch, SetStateAction } from 'react'

import classes from './UserProfileInformation.module.css'
import noProfileImage from 'Assets/Images/noProfileImage.svg'
import Button from 'Components/Button/Button'
import Input from 'Components/Input/Input'
import DropdownWithSearch from 'Components/DropdownWithSearch/DropdownWithSearch'
import nigerianStatesAndLgas from '../../Utilities/nigerianStatesAndLgas'
import countries from '../../Utilities/countries'
import Svgs from 'Assets/Svgs'
import TextArea from 'Components/TextArea/TextArea'
import { TTutor } from 'Services'

const ProfileInformation = (props: {
  onboarding: TTutor
  setOnboarding: Dispatch<SetStateAction<TTutor>>
  handleSubmit: () => void
  isSubmitting: boolean
}) => {
  const { onboarding, setOnboarding, isSubmitting, handleSubmit } = props

  const [profileImageUrl, setProfileImageUrl] = useState('')

  // Utils
  const handleImage = (e: any) => {
    const reader = new FileReader()

    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImageUrl(reader.result as string)
      }
    }

    reader.readAsDataURL(e.target.files[0])

    setFieldValue('profile_image', e.target.files[0])
  }

  const setFieldValue = (name: string, value: string) => {
    setOnboarding((state) => ({ ...state, [name]: value }))
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { target } = event

    setOnboarding((state) => ({ ...state, [target.name]: target.value }))
  }

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Personal information</h4>
        <p>Let’s get to know you. It’ll take less than 5 minutes.</p>
      </div>

      <div className={classes.profilePhoto}>
        <p>Upload your profile picture</p>

        <div>
          <img
            src={!profileImageUrl ? noProfileImage : profileImageUrl}
            alt="Profile"
          />
          <div className={classes.buttonUpper}>
            <input type="file" id="profilePhoto" onChange={handleImage} />
            <label htmlFor="profilePhoto">Browse</label>
            <Button type="secondary">Remove</Button>
          </div>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div>
          <Input
            label="First Name"
            name="first_name"
            isRequired
            errorMessage="Check the first name and try again"
            placeholder="e.g John"
            onChange={handleChange}
            value={onboarding?.first_name || ''}
          />

          <Input
            label="Last Name"
            name="last_name"
            isRequired
            errorMessage="Check the last name and try again"
            placeholder="e.g Doe"
            onChange={handleChange}
            value={onboarding?.last_name || ''}
          />

          <Input
            label="Headline"
            name="headline"
            isRequired
            errorMessage="Check the last name and try again"
            placeholder="E.g Recruiter at Google"
            onChange={handleChange}
            value={onboarding?.headline || ''}
          />
        </div>
        <div>
          <DropdownWithSearch
            title="Select Gender"
            options={['Male', 'Female', 'Prefer not to say']}
            label="Gender"
            isRequired
            selected={onboarding.gender}
            setSelected={(value) => {
              setFieldValue('gender', value)
            }}
            errorMessage="Select an gender to continue "
          />
        </div>
        <div>
          <DropdownWithSearch
            isRequired
            label="Country"
            title="Select Country"
            options={countries.map((item) => item.name)}
            selected={onboarding.country}
            setSelected={(value) => {
              setFieldValue('country', value)
              setFieldValue('state', '')
            }}
            errorMessage="Country is required"
          />
        </div>

        <div>
          {onboarding?.country?.toLowerCase() !== 'nigeria' ? (
            <Input
              isRequired
              label="State"
              name="state"
              placeholder="e.g New york"
              onChange={handleChange}
              value={onboarding.state || ''}
              errorMessage="State is required"
            />
          ) : (
            <DropdownWithSearch
              isRequired
              label="State"
              title="Select State"
              options={nigerianStatesAndLgas.map((item) => item.name)}
              selected={onboarding.state}
              setSelected={(value) => setFieldValue('state', value)}
              errorMessage="State is required"
            />
          )}
        </div>

        <TextArea
          isRequired
          label="Bio"
          name="bio"
          placeholder="Tell us more about yourself, and your achievements "
          onChange={handleChange}
          value={onboarding?.bio || ''}
          errorMessage="Bio is required"
        />

        <Input
          isRequired
          label="Linkedin"
          name="linkedIn_profile"
          placeholder="Enter your LinkedIn URL"
          tip="Copy and paste your LinkedIn URL"
          onChange={handleChange}
          value={onboarding?.linkedIn_profile || ''}
          errorMessage="Linkedin is required"
        />

        <div className={classes.buttonSection}>
          <Button
            loading={isSubmitting}
            onClick={handleSubmit}
            disabled={
              !onboarding.country ||
              !onboarding.state ||
              !onboarding.gender ||
              !onboarding.first_name ||
              !onboarding.last_name ||
              !onboarding.bio ||
              !onboarding.headline ||
              !onboarding.linkedIn_profile
            }
          >
            <span>
              <span>Save and continue</span>
              <Svgs.Arrow className="text-white" />
            </span>
          </Button>
        </div>
      </form>
    </section>
  )
}

export default ProfileInformation
